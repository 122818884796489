<template>
  <standard-page title="Attestation Requests" :definition="$DEFINITIONS.attestationRequests">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'attestation-requests'}">Attestation Requests</router-link>
      </span>
      <span v-if="short_name">DID: {{short_name | truncate(40, '.....')}}</span>
    </template>
    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" @click="navigate(0)" class="nav-link" data-toggle="tab" href="#account">DID Record</a>
          <a :class="{active: activeTab === 1}" @click="navigate(1)" class="nav-link" data-toggle="tab" href="#wallet">Claims</a>
        </nav>
        <div class="az-tab-content">
          <div :class="{'d-none': activeTab !== 0}" id="record">
            <did-properties-for-attestation :catalogid="catalogid" :did="did" />
          </div><!-- az-profile-body -->
          <div :class="{'d-none': activeTab !== 1}" id="claims">
            <claims-for-attestation :did="did" />
          </div><!-- az-profile-body -->
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import ClaimsForAttestation from "./ClaimsForAttestation";
import DidPropertiesForAttestation from "./DidPropertiesForAttestation";

export default {
  name: "ViewRequestedAttestation",
  props: ['catalogid', 'did', 'short_nameProp'],
  components: { ClaimsForAttestation, DidPropertiesForAttestation },
  data() {
    return {
      short_name: this.short_nameProp,
      activeTab: 1
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (!this.short_name) {
        let reply = await this.$identity.get(`/identity/dids/${this.did}`);
        this.short_name = reply.data ? reply.data.short_name : "";
      }
    },
    navigate(index) {
      this.activeTab = index;
    }
  }
}
</script>

<style scoped>
</style>