<template>
    <div class="row mt-4">
        <div class="col-sm-1 pd-l-0 per-page-select">
            <select class="form-control mx-sm-3 rounded" v-model="per_page">
                <option v-for="size in pageSizes">{{size}}</option>
            </select>
        </div>
        <div class="col-sm-1 pd-t-5 pd-l-10">items/page</div>
        <div class="col-sm" v-if="pages>0">
            <ul class="pagination justify-content-end mg-b-0">
                <li @click="first()" class="page-item" v-if="showEndButtons">
                    <a class="page-link" href="javascript:void(0)" style="font-family:ionicons;font-size:20px">&#xF3CF;&#xF3CF;</a>
                </li>
                <li @click="prev()" class="page-item" v-if="showEndButtons">
                    <a class="page-link" href="javascript:void(0)">
                        <i class="icon ion-ios-arrow-back"/>
                    </a>
                </li>
                <template v-for="index in Math.min(pages,pagerWidth)">
                    <li
                            :class="{'active': index === page-pagesStart+1 }"
                            :key="index"
                            @click="goToPage(index+pagesStart-1  )"
                            class="page-item"
                    >
                        <a
                                class="page-link"
                                href="javascript:void(0)"
                        >{{index+pagesStart}}</a>
                    </li>
                </template>
                <li
                        @click="next()"
                        class="page-item"
                        v-if="showEndButtons"
                >
                    <a
                            class="page-link"
                            href="javascript:void(0)"
                    >
                        <i class="icon ion-ios-arrow-forward"/>
                    </a>
                </li>
                <li
                        @click="last()"
                        class="page-item"
                        v-if="showEndButtons"
                >
                    <a
                            class="page-link"
                            href="javascript:void(0)"
                            style="font-family:ionicons;font-size:20px"
                    >
                        &#xF3D1;&#xF3D1;
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        name: "pager",
        props: ['pages'],
        components: {},
        computed: {
            showEndButtons() {
                return this.pages > this.pagerWidth;
            },
            // enableFirst() {
            //   return this.page < pagerWidth;
            // },
            // enablePrev() {
            //   return this.pages > pagerWidth;
            // },
            // enableNext() {
            //   return this.pages > pagerWidth;
            // },
            // enableLast() {
            //   return this.pages > pagerWidth;
            // }
        },
        data() {
            return {
                pagerWidth: 10,
                pagesStart: 0,
                pageSizes: [5, 10, 20, 50],
                page: 0,
                per_page: parseInt(localStorage.getItem("per_page")) || 5
            };
        },
        watch: {
            pages: function (nv, ov) {
                if (this.page > nv) {
                    this.page = 0;
                }
            },
            page: function (nv, ov) {
                this.$emit('pageChange', nv);
            },
            per_page: function (nv, ov) {
                localStorage.setItem("per_page", nv);
                this.$emit('perPageChange', nv);
            }
        },
        mounted() {
            this.$emit('pagerDefaults', {page: this.page, per_page: this.per_page});
        },
        methods: {
            next() {
                if (this.page < this.pages - 1) {
                    let newPage = Math.min(this.page + this.pagerWidth, this.pages - 1);
                    if (this.pages > this.pagerWidth) {
                        if (newPage > this.pagesStart + this.pagerWidth - 1) {
                            this.pagesStart = Math.min(this.pagesStart + this.pagerWidth, this.pages - this.pagerWidth);
                        }
                    }
                    this.page = newPage;
                }

            },
            prev() {
                if (this.page !== 0) {
                    let newPage = Math.max(this.page - this.pagerWidth, 0);
                    if (this.pages > this.pagerWidth) {
                        if (newPage < this.pagesStart) {
                            this.pagesStart = Math.max(this.pagesStart - this.pagerWidth, 0);
                        }
                    }
                    this.page = newPage;

                }
            },
            first() {
                if (this.page !== 0) {
                    this.pagesStart = 0;
                    this.page = 0;

                }

            },
            last() {
                if (this.page !== this.pages - 1) {
                    this.pagesStart = Math.max(this.pages - this.pagerWidth, 0);
                    this.page = this.pages - 1;

                }

            },
            goToPage(newPage) {
                this.page = newPage;

            }
        }
    }
</script>

<style scoped>
    .per-page-select {
        max-width: 80px;
        min-width: 80px;
    }
</style>