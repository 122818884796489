<template>
    <div>
        <div class="row row-sm mg-t-20 mg-b-20">
            <div class="col-8">
                <p v-html="$DEFINITIONS.claim"></p>
            </div>
            <div class="col-4">
                <router-link :to="{name: 'newclaim', params: { did: did }}" class="d-none float-right d-xl-block btn btn-orange rounded font-weight-bold" title="Add Claim">
                    <i class="fas fa-plus-circle mg-r-10"/>Add Claim
                </router-link>
                <router-link :to="{name: 'newclaim', params: { did: did }}" class="d-xl-none btn btn-icon float-right btn-orange rounded w-35" title="Add Claim">
                    <i class="typcn typcn-document-add"/>
                </router-link>
            </div>
        </div>
        <data-table :api="api" :columns="columns" @ready="onReady" list-key="claims" tableId="claims-table"></data-table>
        <div class="modal" id="inputExpiryModal">
            <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-body">
                        <div class="row row-sm form-group">
                            <div class="col-md-12 col-lg-12">
                                <p class="mg-b-10 mg-t-20" v-html="$DEFINITIONS.attestationAssertion"></p>
                                <br>
                                <label class="form-control-label">Attestation Expiry: <span class="tx-danger">*</span></label>
                                <input class="form-control rounded" data-vv-as="Expiry" id="expiry" name="expiry" type="date" v-model="verification_body.expiry">
                            </div>
                        </div>
                        <div class="row row-sm form-group">
                            <div class="col-md-12 col-lg-12">
                                <label class="form-control-label">Current location: </label>
                                <input class="form-control rounded" data-vv-as="Location" id="location" name="location" type="text" v-model="verification_body.location">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="createVerification" class="btn btn-indigo rounded" data-dismiss="modal" type="button">Submit
                        </button>
                        <button class="btn btn-light rounded" data-dismiss="modal" type="button">Close
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <view-attestation-modal :claimid="claim_id" :did="did" v-if="claim_id >= 0"></view-attestation-modal>

    </div>
</template>

<script>
    import EventBus from "@/event-bus";
    import ViewAttestationModal from "../../catalogs/actions/dids/actions/claim/ViewAttestationModal";

    export default {
        name: "ClaimsForAttestation",
        components: {ViewAttestationModal},
        props: ['did'],
        data() {
            return {
                api: `${this.$identity.defaults.baseURL}/identity/dids/${this.did}/claims`,
                columns: [
                    {title: 'Claim', data: 'description'},
                    {
                        type: 'statements',
                    },
                    {type: 'created', title: "Issued On"},
                    {type: 'extrinsic'},
                    {title: 'Status', data: 'current_attestations_count', render: data => data > 0 ? `<span class="attested">Attested</span>` : `<span class="notattested">Not attested</span>`},
                    {
                        type: 'action',
                        defaultContent: `
                          <div class="btn-icon-list">
                                <button data-target="#inputExpiryModal" data-toggle="modal" class="btn rounded btn-indigo btn-icon attest_claim" title="Attest Claim">
                                    <i class="typcn typcn-pen"></i>
                                </button>
                                <button class="btn rounded btn-success btn-icon view_claim" title=""View Claim Attachments & Attestations">
                                    <i class="typcn typcn-eye"></i>
                                </button>
                          </div>`
                    },
                ],
                table: null,
                claim_id: null,
                verification_body: {
                    expiry: new Date().getFullYear() + "-" + new Date().getMonth() + 1 + "-" + new Date().getDate() + " 00:00",
                    location: null
                },
            };
        },
        mounted() {
            // AmazeUI Datetimepicker
            $('#datetimepicker').datetimepicker({
                format: 'yyyy-mm-dd hh:ii',
                autoclose: true
            });
        },
        methods: {
            onReady(table) {
                this.table = table;
                let self = this;

                $(async function () {
                    $('#claims-table tbody').on('click', '.attest_claim', function () {
                        let entity = table.row($(this).parents('tr')).data();
                        self.setClaimId(entity.claim);
                    });
                    $('#claims-table tbody').on('click', '.view_claim', function () {
                        let entity = table.row($(this).parents('tr')).data();
                        self.viewAttestation(entity.claim);
                    });

                });
            },
            setClaimId(claimid) {
                this.claim_id = claimid;
            },
            viewAttestation(claimid) {
                this.claim_id = claimid;
                this.$router.push({name: 'view-claim-for-issuer', params: {did: this.did, claimid: claimid}})
            },
            async createVerification() {
                this.verification_body.expiry = this.verification_body.expiry + 'T00:00:00.000000';
                try {
                    EventBus.$emit('openLoader');
                    await this.$identity.post(`/identity/dids/${this.did}/claims/${this.claim_id}/attestations`, this.verification_body);
                    this.$toastr.s("Claim Successfully attested!", 'Success');
                    this.table.ajax.reload();
                    // this.is_attested = true;
                    this.claim_id = null;
                    this.$root.$emit('refreshAttestation');
                } catch (e) {
                    console.log(e);
                    this.$toastr.s("Failed to attest claim!", 'Failed');
                } finally {
                    EventBus.$emit('closeLoader');
                }
            },

        },
    }
</script>

<style scoped>
    .btn-orange {
        color: white !important;
    }
</style>