<template>
  <div
    class="modal"
    id="viewattestationmodal"
  >
    <div
      class="modal-dialog modal-xl"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-body">
          <button
            aria-label="Close"
            class="close pos-absolute t-15 r-20 tx-26"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <span class="modal-title mg-b-5">Attestation</span>
          <p class="mg-b-20">An attestation record is a proof of the data veracity created by a KYC provider</p>
          <div
            class="table-responsive"
            v-if="filterd_verifications.length >0"
          >
            <table class="table table-striped mg-b-0">
              <thead>
                <tr>
                  <th class="tx-left">#</th>
                  <th class="tx-left">Verified By</th>
                  <th class="tx-left">Verified On</th>
                  <th class="tx-left">Transaction</th>
                  <th class="tx-left">Expiry</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-bind:key="index"
                  v-for="(verification, index) in filterd_verifications"
                >
                  <td class="masked">{{page*per_page +index+1}}</td>
                  <td>{{verification.verified_by}}</td>
                  <td>{{verification.created | timestamp}}</td>
                  <extrinsic-link :extrinsic="verification.extrinsic"></extrinsic-link>
                  <td>{{verification.expiry | timestamp}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="card-body card-blue"
            v-else
          >
            <h4 class="card-title text-muted text-center">No records found</h4>
          </div>

          <pager
            v-bind:pages="pages"
            @pageChange="pageChange"
            @perPageChange="perPageChange"
            @pagerDefaults="pagerDefaults"
          ></pager>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-light rounded font-weight-bold"
            data-dismiss="modal"
            type="button"
          >Close</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ExtrinsicLink from '@/components/ExtrinsicLink';
import Pager from '@/components/Pager';
export default {
  name: "ViewAttestationModal",
  components: { Pager, ExtrinsicLink },
  props: ['did', 'claimid'],
  watch: {
    claimid: function (nv, ov) {
      this.init(nv);
    }
  },
  data() {
    return {
      verifications: [],
      kyc_provider_pub_keys: [],
      filterd_verifications: [],
      pages: 1,
    };
  },
  mounted() {
    this.$root.$on('refreshAttestation', () => {
      this.init();
    });

  },
  methods: {
    pageChange(page) {
      this.page = page;
      this.getCatalogs();
    },
    perPageChange(per_page) {
      this.per_page = per_page;
      this.getVerificationDetails();
    },
    pagerDefaults(pageInfo) {
      this.page = pageInfo.page;
      this.per_page = pageInfo.per_page;
      this.getVerificationDetails();
    },
    async init() {
      await this.getVerificationDetails();
    },
    async getVerificationDetails() {
      if (typeof this.claimid != 'undefined' && this.claimid !== null) {
        this.verifications = [];
        try {
          let params = {
            page: this.page,
            per_page: this.per_page
          };
          let reply = await this.$identity.get(`/identity/dids/${this.did}/claims/${this.claimid}/attestations`, { params });
          this.verifications = reply.data ? reply.data.attestations : [];
          this.kyc_provider_pub_keys = _.map(this.verifications, (verification) => {
            return verification.verified_by;
          });
        } catch (e) {
          this.$toastr.e("Error retrieving Verification Details", "Error");
          console.log(e);
        }
        await this.getOrgsByPubKey();
      }
    },
    async getOrgsByPubKey() {
      this.filterd_verifications = [];
      try {
        let reply = await this.$accounts.post(`/accounts/org/by_public_keys`, {
          pub_keys: this.kyc_provider_pub_keys
        });
        let orgs_by_pub_key = reply.data ? reply.data.orgs : [];
        _.forEach(this.verifications, (verification) => {
          let filtered_org = _.filter(orgs_by_pub_key, { pub_key: verification.verified_by });
          verification.verified_by = filtered_org[0].name + " <" + filtered_org[0].email + ">";
          this.filterd_verifications.push(verification);
        });
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>
</style>